.item-container {
  padding: 5px;
  width: 95%;
}
.item-container .room-name {
  width: 100%;
  font-weight: bold;
}

.item-container .floor {
  width: 100%;
  font-size: small;
}

.item-container .floor-name {
  width: 100%;
  color: #7ac142;
}

.item-icon {
  width: 5%;
  float: right;
}
