.header-user-toolbar {
  background: #7ac142;
  margin-bottom: 3em;
  color: #fff;
  height: 135px;
}

.header-user-toolbar .curve {
  position: relative;
  display: block;
  height: 150px;
  bottom: -140px;
  margin-top: -150px;
  overflow: hidden;
  z-index: 1;
}

.header-user-toolbar .curve::after {
  border-radius: 70%;
  box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.05);
  height: 100px;
  bottom: 0;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 112%;
  z-index: -1;
  background: #7ac142;
}

.header-user-toolbar h1,
.header-user-toolbar h3 {
  text-align: center;
}

.header-user-toolbar .ellipse1 {
  background: #0000000f;
  width: 249px;
  height: 275px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  left: -70px;
  top: -40px;
  pointer-events: none;
}

.header-user-toolbar .ellipse2 {
  position: absolute;
  width: 376px;
  height: 315px;
  left: -95px;
  top: -55px;
  border-radius: 50%;
  background: #0000000f;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  }

.header-user-toolbar .header-user-content {
  position: relative;
  z-index: 2;
}

.header-user-content .header-table {
  width: 100%;
  height: 100%;
}

.header-table .header-icon-menu-td {
  width: 20%;
  vertical-align: top;
  text-align: center;
  padding: 15px;
}

.header-table .header-icon-notification-td {
  vertical-align: top;
  text-align: center;
  width: 20%;
  padding: 15px;
}

.header-table .header-icon-td {
  text-align: center;
  width: 60%;
}

.header-table .header-icon-td .logo {
  width: 24px;
  padding-top: 15px;
}

.header-user-content .header-title {
  text-align: center;
  margin-top: -20px;
}

.header-user-toolbar .header-title .title {
  color: white;
  font-weight: bold;
  font-size: 1em;
  overflow: hidden;
  text-overflow: clip;
  word-break: break-all;
  min-height: 20px;
  max-height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.header-user-toolbar .header-title .subtitle {
  color: white;
  font-size: 13.5px;
  text-overflow: clip;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 15px;
}

.header-user-toolbar .header-title .title2 {
  font-weight: bold;
  overflow: hidden;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-user-toolbar .header-title .title2:after {
  display: inline-block;
  width: 22px;
  height: 22px;
  content: "";
  background: url(../../../../public/refresh.png) no-repeat 0 0;
  background-position: bottom;
  background-size: 92%;
}

.header-user-toolbar .header-title .subtitle2 {
  color: white;
  text-overflow: clip;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 40px;
  width: 90%;
  vertical-align: middle;
  margin: 0 auto;
  font-size: 12.5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.notification-icon {
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.badge-notification {
  position: fixed; 
  height: 8px; 
  width: 8px;
  background: red;
  border-radius: 99px;
  margin-left: 14px;
  margin-top: -7px;
}
