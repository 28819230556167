.table-toolbar-user {
  width: 100%;
}
.table-toolbar-user .tool-bar-title {
  line-height: 1;
}

.table-toolbar-user .tool-bar-title .title {
  margin: 0px;
  font-weight: bold;
}

.table-toolbar-user .tool-bar-title .subtitle {
  margin: 0px;
  font-size: 14px;
  font-weight: normal;
}
.table-toolbar-user .tool-bar-notifications {
  width: 4%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  text-align: center;
  vertical-align: middle;
}
.table-toolbar-user .tool-bar-photo {
  width: 4%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  vertical-align: middle;
}
.table-toolbar-user .tool-bar-info {
  width: 15%;
  padding-left: 10px;
  text-align: left;
  vertical-align: middle;
  line-height: 1;
}

.notification-icon-desktop {
  cursor: pointer;
}

.badge-notification-desktop {
  position: fixed; 
  height: 8px; 
  width: 8px;
  background: red;
  border-radius: 99px;
  margin-left: 8px;
  margin-top: -6px;
}
