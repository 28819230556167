.ticket-container {
  width: 100%;
  height: 100%;
  /* padding-bottom: 25px; */
  /* background-color: red; */
}

.ticket-container .upper-inf {
  width: 100%;
  height: 50%;
  /* background-color: rgb(0, 174, 255); */
  border-bottom: 2px rgb(113, 113, 113) dashed;
  position: relative;
}

.ticket-container .info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.ticket-container .info-container p {
  text-align: center;
  width: 100%;
  color: white;
  font-size: 22px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.ticket-container .info-container .sub-text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
}

.ticket-container .upper-inf .left-detail {
  width: 50px; /* half of width*/
  height: 100px;
  border-radius: 0 150px 150px 0;
  background-color: #2a2d38;
  position: absolute;
  bottom: -50px;
}

.ticket-container .upper-inf .rigth-detail {
  width: 50px; /* half of width*/
  height: 100px;
  border-radius: 150px 0 0 150px;
  background-color: #2a2d38;
  position: absolute;
  bottom: -50px;
  right: 0px;
}

.ticket-container .lower-inf {
  width: 100%;
  height: 50%;
  /* background-color: rgb(204, 255, 0); */
  float: left;
}

.ticket-container .lower-inf .info-number {
  margin: 0;
  font-size: 100px;
  color: #f8931c;
}
