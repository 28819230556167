.attendance-list-studentclass {
  background-color: rgb(70, 75, 91);
}

.studentName {
  text-align: left;
  padding-left: 15px;
}

.button-present {
  background-color: hsla(94, 51%, 51%, 0.2);
  border-radius: 5px;
  color: #7ac142;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  width: 100px;
  float: right;
}

.button-tardy {
  background: rgba(248, 231, 28, 0.2);
  border-radius: 5px;
  color: #f8e71c;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  width: 100px;
  float: right;
}

.button-absent {
  background: rgba(255, 78, 50, 0.2);
  border-radius: 5px;
  color: #ff4e32;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  width: 100px;
  float: right;
}

.attendance-list-studentclass .class-status {
  width: 20%;
  text-align: center;
  vertical-align: middle;
  padding-top: 2px;
}
