.desktop-calendar {
  display: flex;
  min-height: 100%;
  font-family: '-apple-system','Gotham','"Segoe UI"',
               '"Helvetica Neue"','Arial','sans-serif','"Apple Color Emoji"','"Segoe UI Emoji"','"Segoe UI Symbol"';
}

.desktop-calendar-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #2992bc;
  border: 1px solid rgba(42, 45, 56, 1);  
}

.fc-theme-standard .fc-scrollgrid
{
  border: none;
}

.fc-theme-standard td, .fc-theme-standard th
{
  border: 1px solid rgba(42, 45, 56, 1);
  background: #3D404E;
  text-align: left;
  font-size: 14px;
  /* padding: 1px; */
  font-weight: 500;
  color: white;
  /* padding-top: 2.5px;
  padding-bottom: 2.5px; */
}

.fc .fc-toolbar-title
{
  font-size: 1.55em;
  font-family: '-apple-system','Gotham','"Segoe UI"',
  '"Helvetica Neue"','Arial','sans-serif','"Apple Color Emoji"','"Segoe UI Emoji"','"Segoe UI Symbol"'
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child)
{
  text-transform: capitalize;
  font-size: 14px;
}

.fc .fc-daygrid-day-top
{
  flex-direction: row !important;
}

.desktop-calendar-sidebar-section {
  padding: 2em;
}

.fc .fc-col-header-cell-cushion{
  font-family: 'Inter';
  font-weight: 400;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child)
{
  text-transform: capitalize;
  font-size: 14px;
}

.fc .fc-daygrid-day-number
{
  padding-left: 7px;
}

.fc-day-sun, .fc-day-sat
{
  background-color: #323643 !important;
  color: #71737d !important;
}

.desktop-calendar-main {
  flex-grow: 1;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

th[role="columnheader"]
{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .fc-h-event{
  border: 1px solid var(--fc-event-border-color, #7ac142);
  background-color: #7ac142 !important;
} */

.fc-toolbar-chunk:nth-child(3)  {
  visibility: collapse;
}