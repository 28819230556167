.tabDashboard {
    height: 40px;
    width: 160px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.tabDashboardActive {
    background: #FFFFFF;
    border-radius: 6px;
    color: #7AC142;
}

.cardContent {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.tabsGrid{
    margin: 0 !important;
    padding: 0 7px !important;
}
