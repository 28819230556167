.hero {
  background: #7ac142;
  margin-bottom: 3em;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.hero .curve {
  position: relative;
  display: block;
  height: 150px;
  bottom: -140px;
  margin-top: -100px;
  overflow: hidden;
  z-index: 1;
}
.hero .curve::after {
  border-radius: 70%;
  box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.05);
  height: 100px;
  bottom: 0;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: -1;
  background: #7ac142;
}
.hero h1,
.hero h3 {
  text-align: center;
}

.hero .ellipse1 {
  background: #0000000f;
  width: 249px;
  height: 275px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  left: -70px;
  top: -40px;
}

.hero .ellipse2 {
  position: absolute;
  width: 376px;
  height: 315px;
  left: -95px;
  top: -55px;
  border-radius: 50%;
  background: #0000000f;
  overflow: hidden;
  position: absolute;
  z-index: 2;
}

.hero-content {
  position: relative;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  transform: translateY(23%);
  z-index: 10;
  display: flex;
}

.hero-content-multiline {
  position: relative;
  text-align: center;
  justify-content: space-around;
  align-content: center;
  align-items: flex-start;
  transform: translateY(23%);
  z-index: 10;
  display: flex;
}
