@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.App {
  text-align: center;
  font-family: "gotham";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.default-container {
  background: #3c404e;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  overflow: auto;
  margin-top: -50px;
  text-align: center;
  min-height: 79vh;
  max-height: 79vh;
  z-index: 3;
}
.default-loading {
  width: 100%;
}

.container-box {
  background-color: "#3C404E";
  border-radius: "5px 5px 0 0";
  box-shadow: "0px 4px 14px rgba(0, 0, 0, 0.25)";
  overflow: "auto";
  margin-top: "-50px";
  text-align: "center";
  min-height: "79vh";
  max-height: "79vh";
  z-index: 3;
}
