.perc-container {
  background-color: #7ac142;
  width: 100vw;
  height: 145px;
  text-align: center;
  margin-top: -15px;
}

.perc-container-desk {
  width: 100%;
  height: 145px;
  text-align: center;
  margin-top: -50px;
}

.perc-graph {
  /* background-color: red; */
  text-align: center;
  width: 110px;
  height: 110px;
  margin: auto;
}

.perc-graph-mini {
  text-align: center;
  width: 75px;
  height: 75px;
  margin: auto;
}

.perc-number-mini
{
  margin: auto;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 31px;
  margin-left: 25px;
  font-size: 15px;
}

.perc-number {
  margin: auto;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 48px;
  margin-left: 38px;
  font-size: 18px;
}

.perc-sub-title {
  color: white;
  width: 100%;
  font-weight: bold;
  margin-top: 5px;
  font-size: 14px;
  letter-spacing: 1.1px;
}
