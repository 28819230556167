.attendance-list-studentclass2 {
  width: 100%;
  padding-top: 10px;
}

.attendance-list-studentclass2 tbody tr td {
  border-bottom: #3c404e solid 1px;
  width: 60%;
  padding: 12.5px 8px;
}

.attendance-list-studentclass {
  background-color: rgb(70, 75, 91);
}

.studentNameAtLocation {
  text-align: left;
  padding-left: 15px;  
}

.studentNameAtLocation:hover {
  background: linear-gradient(0deg, #54596d, #596075), #009cde;
  cursor: pointer !important;
}

.attendance-list-studentclass2 .class-status {
  width: 35%;
  text-align: center;
  vertical-align: middle;
}
