.schedule-list-selector-date {
  width: 100%;
  height: 100%;
}

.schedule-list-selector-date table {
  width: 100%;
}

.schedule-list-selector-date .curr-date {
  width: 50%;
  text-align: left;

  color: rgba(255, 255, 255, 0.75);
}

.schedule-list-selector-date .curr-date p {
  margin: 0px;
}

.schedule-list-selector-date .cal-button {
  width: 40px;
  height: 40px;
  background-color: #7ac142;
  border-radius: 50%;
  float: right;
  margin-right: 15px;
  background-image: url("../Assets/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}
.schedule-list-selector-date .cal-button:hover {
  cursor: pointer;
}

.sechedule-title {
  color: white;
  text-align: left;
  font-size: 25px;
  font-weight: bold;
}
