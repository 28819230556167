.table-photo-profile {
  margin-bottom: 20px;
}

.td-photo-profile {
  padding: 10px;
  text-align: left;
}

.td-button {
  padding-left: 10px;
}

.td-button .button-photo-profile {
  color: white;
  text-transform: capitalize;
  width: 180px;
}

.account-management {
  width: 100%;
}

.account-management .title {
  font-weight: bold;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
}

.account-management .button-change-pass-container {
  text-align: right !important;
  width: 100%;
}

.button-change-pass-container .button-change-pass {
  text-transform: capitalize;
  color: white;
  margin-left: 0;
}
