.container {
  width: 100%;
}

.result {
  margin-top: 100px;
  word-wrap: break-word;
  width: 400px;
}

.camera-container-ui {
  padding-left: 5px;
  padding-top: 5px;
  width: 327px;
  height: 250px;
  background: linear-gradient(to right, white 4px, transparent 4px) 0 0,
    linear-gradient(to right, white 4px, transparent 4px) 0 100%,
    linear-gradient(to left, white 4px, transparent 4px) 100% 0,
    linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
    linear-gradient(to top, white 4px, transparent 4px) 0 100%,
    linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.camera-container {
  text-align: center;
  width: 240px;
  height: 240px;
}

#html5qr-code-full-region {
  border: none !important;
}

#html5-qrcode-button-camera-permission {
  position: relative;
  left: 15%;
  background-color: #7ac142; 
  color: #fff; 
  border: 1px solid #7ac142; 
  border-radius: 4px; 
  padding: 8px 16px; 
  cursor: pointer;
  text-align: center;
  text-transform: none; 
  transition: background-color 0.3s, border 0.3s; 
  &:hover {
    background-color: #000; 
    border: 1px solid #000; 
  }
}

#html5-qrcode-button-camera-stop {
  position: relative;
  left: 15%;
  background-color: #7ac142; 
  color: #fff; 
  border: 1px solid #7ac142; 
  border-radius: 4px; 
  padding: 8px 16px; 
  cursor: pointer;
  text-align: center;
  text-transform: none; 
  transition: background-color 0.3s, border 0.3s; 
  margin-top: 2%;
  &:hover {
    background-color: #000; 
    border: 1px solid #000; 
  }
}

#html5-qrcode-button-camera-start {
  position: relative;
  left: 15%;
  background-color: #7ac142; 
  color: #fff; 
  border: 1px solid #7ac142; 
  border-radius: 4px; 
  padding: 8px 16px; 
  cursor: pointer;
  text-align: center;
  text-transform: none; 
  transition: background-color 0.3s, border 0.3s; 
  &:hover {
    background-color: #000; 
    border: 1px solid #000; 
  }
}

#html5qr-code-full-region video {
  width : 133% !important;
}

#html5qr-code-full-region div:nth-child(2) img{
  margin-left: 28%;
  margin-top: 18%;
}
#html5qr-code-full-region__dashboard_section_csr span:nth-child(1) {
  margin-left: 33%;
}
#html5-qrcode-select-camera {
  margin-left: 5%;
  margin-bottom: 4%;
  background-color: #7ac142;
  border: 1px solid #7ac142;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  left: 15%;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: background-color .3s,border .3s;
}

#html5-qrcode-button-torch {
  display: inline-block;
  margin-left: 5px;
  background-color: #7ac142;
  border: 1px solid #7ac142;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  left: 15%;
  padding: 8px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: background-color .3s,border .3s;
  margin-top: 2%;
}
#qr-shaded-region {
  display: none;
}




@media only screen and (max-width: 768px) {
  .camera-container-ui {
    padding-left: 5px;
    padding-top: 5px;
    width: 240px;
    height: 240px;
    background: linear-gradient(to right, white 4px, transparent 4px) 0 0,
      linear-gradient(to right, white 4px, transparent 4px) 0 100%,
      linear-gradient(to left, white 4px, transparent 4px) 100% 0,
      linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
      linear-gradient(to top, white 4px, transparent 4px) 0 100%,
      linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  #html5qr-code-full-region video {
    width : 230px !important;
    height: 230px !important;
    object-fit: cover;
  }

  #html5qr-code-full-region__dashboard {
    display: block;
    left: -37px;
    position: relative;
  }
}