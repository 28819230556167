.attendance-list-container {
  border: 1px #7ac142 solid;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.attendance-list-container-desk {
  border: 1px #7ac142 solid;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.attendance-list-title {
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 8px;
  padding-left: 10px;
  font-weight: bold;
}

.attendance-list-selector-date {
  background-color: #3c404e;
  width: 100%;
  height: 100%;
}

.attendance-list-selector-date table {
  width: 100%;
}

.attendance-list-selector-date .curr-date {
  width: 50%;
  text-align: left;
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.75);
}

.attendance-list-selector-date .curr-date p {
  margin: 0px;
}

.cal {
  width: 50%;
  padding: 5px;
  text-align: right;
}

.attendance-list-selector-date .cal-button {
  width: 40px;
  height: 40px;
  background-color: #7ac142;
  border-radius: 50%;
  float: right;
  margin-right: 15px;
  background-image: url("./calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}
.attendance-list-selector-date .cal-button:hover {
  cursor: pointer;
}

.attendance-list-studentclass {
  width: 100%;
  padding-top: 10px;
}

.attendance-list-studentclass .class-info {
  width: 70%;
  text-align: left;
  padding-left: 15px;
}

.attendance-list-studentclass .class-info .class-curr-button {
  background-color: #7ac142;
  border-radius: 15px;
  padding: 2px;
  width: 100px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.1px;
}

.attendance-list-studentclass .class-info h4 {
  width: 100%;
  margin: 0px;
  font-weight: bold;
  font-size: 16px;
  padding-top: 5px;
  letter-spacing: 1.4px;
}

.attendance-list-studentclass .class-info h5 {
  width: 100%;
  margin: 0px;
  font-weight: lighter;
  font-size: 16px;
  letter-spacing: 1.2px;
}

.attendance-list-studentclass .class-curr {
  width: 10%;
  text-align: right;
}

.attendance-list-studentclass .class-curr .icon {
  background-image: url("../../Assets/copyicon.svg");
  background-repeat: no-repeat;
  background-size: 25px;
  width: 25px;
  height: 25px;
  float: right;
  margin-right: 15px;
}

.attendance-list-studentclass .class-status {
  width: 20%;
  text-align: center;
}

.button-present {
  background-color: hsla(94, 51%, 51%, 0.2);
  border-radius: 5px;
  color: #7ac142;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  width: 100px;
  float: right;
}

.button-tardy {
  background: rgba(248, 231, 28, 0.2);
  border-radius: 5px;
  color: #f8e71c;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  width: 100px;
  float: right;
}

.button-absent {
  background: rgba(255, 78, 50, 0.2);
  border-radius: 5px;
  color: #ff4e32;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  width: 100px;
  float: right;
}

.attendance-list-studentclass {
  border-collapse: collapse;
  border-spacing: 0px !important;
}

.attendance-list-studentclass tbody tr td {
  border-bottom: #3c404e solid 1px;
  width: 100%;
  padding-bottom: 2px;
  padding-top: 15px;
}

.attendance-list-studentclass-gray {
  background-color: #202020;
  color: #707070;
}
