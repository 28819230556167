.menu-drawer-container {
  width: 100%;
  height: 100px;
}

.menu-drawer-content {
  background-color: #353535;
}

.menu-drawer-container-logo {
  position: relative;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  background: #7ac142;
  height: 100%;
  z-index: -1;
}

.menu-drawer-container-bg h1,
.menu-drawer-container-bg h3 {
  text-align: center;
}

.menu-drawer-container .menu-drawer-ellipse1 {
  background: #0000000f;
  overflow: hidden;
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  left: -70px;
  top: -50px;
  z-index: -1;
}

.menu-drawer-container .menu-drawer-ellipse2 {
  background: #0000000f;
  overflow: hidden;
  position: absolute;
  width: 350px;
  height: 350px;
  left: -95px;
  top: -120px;
  border-radius: 50%;
  z-index: -1;
}

.button-school-container {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.button-school-container .button-box {
  width: 255px;
  height: 100%;
  background: linear-gradient(0deg, #3c404e, #3c404e), #009cde;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.button-school-container .button-box :hover {
  background: linear-gradient(0deg, #54596d, #596075), #009cde;
  cursor: pointer !important;
  border-radius: 8px;
}

.button-school-container .button-box .button-table {
  width: 100%;
  height: 100%;
}
.button-school-container .button-box .button-table .td-text-button {
  width: 90%;
  text-align: left;
  vertical-align: middle;
  margin-right: 5px;
}

.button-school-container
  .button-box
  .button-table
  .td-text-button
  .text-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
  margin-left: 5px;
}
.button-school-container .button-box .button-table .td-icon-button {
  width: 10%;
  text-align: center;
  vertical-align: middle;
  padding-top: 6px;
}

/* para dispositivos small */
@media only screen and (max-width: 600px) {
  .menu-drawer-content {
    margin-top: 0px;
  }

  .menu-drawer-container {
    height: 180px;
  }
  .menu-drawer-container-logo .logo {
    display: none;
  }

  .menu-drawer-container .menu-drawer-ellipse1 {
    width: 195px;
    height: 229px;
    left: -99px;
    top: 41px;
  }

  .menu-drawer-container .menu-drawer-ellipse2 {
    width: 350px;
    height: 349px;
    left: -152px;
    top: 36px;
  }

  .button-school-container .button-box {
    margin-top: -50px;
  }

  .menu-user-card {
    width: 100%;
    height: 100%;
  }

  .menu-user-card .menu-user-photo-profile {
    width: 100%;
    height: 90px;
    /* background-color: #009cde; */
    float: left;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .menu-user-card .menu-user-photo-profile .photo-profile {
    width: 50px;
    height: 50px;
    background-color: rgb(255, 124, 16);
    margin-top: auto;
    margin-left: 15px;
  }
  .menu-user-card .menu-user-info-profile {
    width: 100%;
    height: 90px;
    /* background-color: #de0089; */
    float: left;
    padding-left: 15px;
    /* padding-top: 5px; */
    text-align: left;
  }

  .menu-user-card .menu-user-info-profile .user-name-profile {
    color: white;
    text-transform: capitalize;
    line-height: 1.2;
    font-size: 12px;
  }

  .menu-user-card .menu-user-info-profile span {
    font-weight: bold;
    font-size: 18px;
  }
}

/* para dispositivos desktop */
@media only screen and (min-width: 600px) {
  .menu-drawer-container-logo img {
    margin-top: 35px;
    width: 45px;
  }

  .menu-drawer-content {
    margin-top: 55px;
  }

  .menu-drawer-container .curve {
    position: relative;
    display: block;
    height: 145px;
    bottom: -140px;
    margin-top: -140px;
    overflow: hidden;
    z-index: -1;
  }

  .menu-drawer-container .curve::after {
    border-radius: 75%;
    box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.05);
    height: 100px;
    bottom: 0;
    transform: translate(-5%, -100%);
    -ms-transform: translate(-5%, -100%);
    -webkit-transform: translate(-5%, -100%);
    content: "";
    position: absolute;
    width: 110%;
    background: #7ac142;
    z-index: -1;
  }

  .menu-user-card {
    display: none;
  }
}
